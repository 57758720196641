import Chance from 'chance'

import { CourierReturn } from '@/CourierReturn/models/CourierReturn'

const chance = new Chance()

export const courierReturnStub = (): CourierReturn => ({
  id: chance.guid(),
  processed_at: chance.date().toISOString(),
  updatedAt: chance.date().toISOString(),
  createdAt: chance.date().toISOString(),
  packageAction: null,
  packageId: chance.string(),
  returnReason: 'damaged',
  returnType: 'unshipped',
  contentDamaged: false,
  packagingDamaged: true,
  label: null,
  labelUsable: null,
})

export const CourierReturnPackageActionsStub = () => ({
  packageActions: ['to_be_destroyed', 'to_be_liquidated'],
})
