import { useQuery } from '@tanstack/react-query'

import { useGateways } from '@/contexts/gateways'

import { CourierReturnReason, CourierReturnType } from './gateways/api/models/CourierReturn'

export default function useGetActionsQuery(
  packageId: string,
  returnType: CourierReturnType,
  returnReason: CourierReturnReason,
  isValid: boolean,
) {
  const { courierReturnGateway } = useGateways()

  const query = useQuery({
    queryKey: ['getCourierReturnPackageActions', returnType, returnReason],
    queryFn: () =>
      courierReturnGateway.getCourierReturnPackageActions(packageId, returnType, returnReason),
    enabled: isValid,
  })

  return query
}
